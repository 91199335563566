export type ItemKey = string

class _Storage {
  loadItem (key: ItemKey) {
    const value = window.localStorage.getItem(key)
    if (!value) {
      return value
    }
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  saveItem (key: ItemKey, value: string | number | object | null | boolean) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

export const Storage = new _Storage()
