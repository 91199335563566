import React, { Component } from 'react'
import {
  CirclePicker,
  ColorResult,
  PhotoshopPicker,
  RGBColor,
  SketchPicker,
  SliderPicker,
  SwatchesPicker
} from 'react-color'
import cx from 'classnames'
import './App.scss'
import { KeyName, SelectableLink } from './components/SelectableLink'
import { Storage } from './lib/Storage'

// @ts-ignore
const PickerStyles = Object.freeze({
  circle: 'Circle',
  swatches: 'Swatches',
  slider: 'Slider',
  photoshop: 'Photoshop',
  sketch: 'Sketch'
})

type PickerStyleName = keyof typeof PickerStyles

const DEFAULT_PICKER_STYLE: PickerStyleName = 'circle'

type Props = any
interface State {
  color: RGBColor,
  width: number,
  height: number,
  imageUrl: string,
  currentPickerStyle: PickerStyleName
}

export class App extends Component<Props, State> {
  state: State = {
    color: {
      r: 244,
      g: 67,
      b: 54,
      a: 1
    },
    width: 320,
    height: 240,
    imageUrl: '',
    currentPickerStyle: DEFAULT_PICKER_STYLE
  }

  canvas: HTMLCanvasElement = document.createElement('canvas')

  constructor (props: Props) {
    super(props)
    const { searchParams } = new URL(document.location.toString())
    console.log(searchParams.get('picker'))
    const pickerParam = searchParams.get('picker')
    if (pickerParam && Object.keys(PickerStyles).includes(pickerParam)) {
      this.state.currentPickerStyle = pickerParam as PickerStyleName
    } else {
      this.state.currentPickerStyle = Storage.loadItem('picker-style') || DEFAULT_PICKER_STYLE
    }

    this.state.color = Storage.loadItem('color') || {
      r: 244,
      g: 67,
      b: 54,
      a: 1
    }
  }

  componentDidMount () {
    const { width, height } = this.state
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    this.updateImageUrl()
  }

  onChangeColor = (colorResult: ColorResult) => {
    Storage.saveItem('color', colorResult.rgb)
    this.setState({ color: colorResult.rgb }, () => {
      this.updateImageUrl()
    })
  }

  onChangeSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.currentTarget
    const { value = '0', dataset } = inputElement
    const { key } = dataset

    if (key !== 'width' && key !== 'height') {
      return
    }

    const newValue = Math.min(Math.max(1, parseInt(value, 10) || 0), 5000)

    const newState: {width?: number, height?: number} = { [key]: newValue }
    this.setState(newState as Pick<State, keyof State>, () => {
      this.updateImageUrl()
    })
  }

  updateImageUrl () {
    const { width, height, color } = this.state
    const { canvas } = this
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (!context) {
      return
    }

    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
    context.fillRect(0, 0, width, height)
    const imageUrl = canvas.toDataURL('image/png')
    this.setState({ imageUrl })
  }

  onSelectColorPicker = (selectedKeyName: KeyName) => {
    // 更新をVDOMに通知
    this.setState({ currentPickerStyle: selectedKeyName as PickerStyleName })

    // ストレージに保存
    Storage.saveItem('picker-style', selectedKeyName)
  }

  onSelectFitSize = (selectedKeyName: KeyName) => {
    const { width, height } = this.state
    switch (selectedKeyName) {
      case 'horiz':
      case 'vert':
        this.setState({ width: height,
          height: width })
        break
      case 'square':
        this.setState({ height: width })
        break
      default:
          // do nothing
    }
  }

  render () {
    const { color, width, height, imageUrl, currentPickerStyle } = this.state
    return (
      <div className={cx('app', { wide: width >= window.innerWidth - 120 })}>
        <header>
          <h1>Filler Color</h1>
        </header>
        <main>
          <section className='generator-container'>
            <h2>1. Choose color:</h2>
            <div className='picker-style-selectors'>
              {
                Object.keys(PickerStyles).map((pickerStyleKey) => {
                  const name = PickerStyles[pickerStyleKey as PickerStyleName]
                  return (
                    <SelectableLink key={pickerStyleKey} keyName={pickerStyleKey} currentKeyName={currentPickerStyle} onSelect={this.onSelectColorPicker}>
                      {name}
                    </SelectableLink>
                  )
                })
              }
            </div>
            <div className='picker-container'>
              {
                currentPickerStyle === 'circle' && <CirclePicker color={color} onChange={this.onChangeColor} />
              }
              {
                currentPickerStyle === 'swatches' && <SwatchesPicker color={color} onChange={this.onChangeColor} />
              }
              {
                currentPickerStyle === 'photoshop' && <PhotoshopPicker color={color} onChange={this.onChangeColor} />
              }
              {
                currentPickerStyle === 'sketch' && <SketchPicker color={color} onChange={this.onChangeColor} />
              }
              {
                currentPickerStyle === 'slider' && <SliderPicker color={color} onChange={this.onChangeColor} />
              }
            </div>
          </section>
          <section className='result-container'>
            <h2>2. Save it, or change the size.</h2>
            <div className='fit-size-selectors'>
              <SelectableLink href='#' keyName='horiz' selected={width > height} disabled={width === height} onSelect={this.onSelectFitSize}>
                <img src='/images/horiz@2x.png' alt='Make it horizontal rectangle.' width={32} height={32} />
              </SelectableLink>
              <SelectableLink href='#' keyName='square' selected={width === height} onSelect={this.onSelectFitSize}>
                <img src='/images/square@2x.png' alt='Make it square.' />
              </SelectableLink>
              <SelectableLink href='#' keyName='vert' selected={width < height} disabled={width === height} onSelect={this.onSelectFitSize}>
                <img src='/images/vert@2x.png' alt='Make it vertical rectangle.' />
              </SelectableLink>
            </div>
            <table className='canvas-container'>
              <tbody>
                <tr>
                  <td />
                  <td className='width-container'>
                    <div className='ruler' />
                    <input type='number' min={1} max={5000} className='width-input' value={width} onChange={this.onChangeSize} data-key='width' />
                  </td>
                </tr>
                <tr>
                  <td className='height-container'>
                    <div className='ruler' />
                    <input type='number' min={1} max={5000} className='height-input' value={height} onChange={this.onChangeSize} data-key='height' />
                  </td>
                  <td>
                    <img src={imageUrl} width={width} height={height} alt='Generated data' />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
        <footer>
          <div className='licenses'>
            <a className='show-licenses' href='/licenses.html' target='_blank' rel='noopener noreferrer'>
              Licenses
            </a>
            &nbsp;|&nbsp;
            <a className='show-licenses' href='/privacy.html' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
          </div>
          <div className='copyright' ><a href='https://twitter.com/akiroom' target='_blank' rel='noopener noreferrer'>@akiroom</a></div>
        </footer>
      </div>
    )
  }
}
