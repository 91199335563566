import React from 'react'
import cx from 'classnames'

export type KeyName = string

interface Props {
  keyName: KeyName,
  href?: string,
  currentKeyName?: KeyName,
  selected?: boolean,
  disabled?: boolean,
  onSelect?: (selectedKeyName: KeyName) => void,
  children?: React.ReactNode
}

export function SelectableLink (props: Props) {
  const { href, keyName, currentKeyName, selected, onSelect, disabled, children } = props
  if (selected || keyName === currentKeyName) {
    return <span>{children}</span>
  }

  return (
    <a
      href={href || `?picker=${keyName}`}
      onClick={
        (e) => {
          e.preventDefault()
          if (onSelect) {
            onSelect(keyName)
          }
        }
      }
      className={cx({ disabled })}
    >
      {children}
    </a>
  )
}
